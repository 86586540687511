import * as React from 'react'
import { AsYouType, PhoneNumber as LibPhoneNumber } from 'libphonenumber-js'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import Input from './Input'

export type PhoneNumberProps = NumberFormatProps & {
  input: { value: string }
  selectedCountry: string
  defaultPhone: LibPhoneNumber
  exampleNumber: LibPhoneNumber
}

export const PhoneNumber = ({ input, selectedCountry, defaultPhone, exampleNumber, ...rest }: PhoneNumberProps) => {
  const formatter = new AsYouType({
    defaultCountry: exampleNumber?.country,
    defaultCallingCode: exampleNumber?.countryCallingCode,
  })
  formatter.input(exampleNumber?.formatNational() || '')
  const template = formatter.getTemplate()
  formatter.reset()
  formatter.input(input.value)

  return (
    <NumberFormat
      {...input}
      {...rest}
      type="tel"
      initialValue={defaultPhone?.formatNational()}
      value={formatter.isInternational() ? formatter.getNationalNumber() : input.value}
      format={formatMask(template, exampleNumber?.countryCallingCode, '#')}
      placeholder={formatMask(template, exampleNumber?.countryCallingCode, '_')}
      mask={'_'}
      customInput={Input}
    />
  )
}
const nthOccurence = (string: string, subString: string, index: number) => string.split(subString, index).join(subString).length

// modify input mask to remove dial code
const formatMask = (mask?: string, dial?: string, newSubstr?: string) => {
  if (!mask || !dial) return undefined

  const startingIndex = nthOccurence(mask, 'x', dial.length)
  let formattedMask

  // account for masks where our starting index is preceeded with a (
  if (mask[startingIndex - 1] === '(') {
    formattedMask = mask.slice(startingIndex - 1)
  } else {
    formattedMask = mask.slice(startingIndex)
  }

  return newSubstr ? formattedMask.replace(/[x]/g, newSubstr) : formattedMask
}

export default PhoneNumber
