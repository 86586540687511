import { merge } from 'lodash'

export type Config = {
  envName: string
  msalClientId: string
  msalAuthority: string
  msalScopes: string[]
  appVersion: string
  appName: string
  serviceProxyUrl: string
  rentalAgreementUrn: string
  vehicleUrn: string
  rentalAgreementEnv: string
  testEmailsCsv: string
  noReplyExchangeEmailsCsv: string
  noReplyEscalationEmailsCsv: string
  recoveryEmailsCsv: string
  noReplyRecoveryEmailsCsv: string
  ldrEmailsCsv: string
  googleMapKey: string
  solrBranchHours: string
  solrSpatial: string
  solrLocator: string
  tireRepairAccounts: {
    Firestone: string
    Goodyear: string
  }
  exchangeEmailsCsv?: string
  zendeskEmailsCsv?: string
}

const appConfig = {
  default: {
    msalClientId: 'b0d4a6e0-8d23-4403-ac41-05202ae5f698',
    msalAuthority: 'https://login.microsoftonline.com/5a9bb941-ba53-48d3-b086-2927fea7bf01',
    msalScopes: ['api://ar.oauth-proxy-np.roadside_assistance_hero/api.read'],
    appVersion: process.env.REACT_APP_VERSION,
    appName: process.env.REACT_APP_NAME,
    serviceProxyUrl: 'https://sandbox.api.ehi.dev/rental/operations/roadside',
    rentalAgreementUrn: 'urn:com.ehi:xqa:rental.fulfillment:rentalAgreement:',
    vehicleUrn: 'urn:com.ehi:xqa:vehicle:fleetVehicle:',
    rentalAgreementEnv: 'xqa',

    testEmailsCsv: 'Lindsay.p.fischer@em.com,jonathon.hughes@em.com',
    noReplyExchangeEmailsCsv: 'noreply-vehicle-exchange-np@em.com',
    noReplyEscalationEmailsCsv: 'noreply-escalation-np@em.com',
    noReplyRecoveryEmailsCsv: 'noreply-vehicle-recovery-np@em.com',
    ldrEmailsCsv: 'Lindsay.p.fischer@em.com',

    googleMapKey: 'AIzaSyA8bPKhJ5OqzMTVqGm9_hPoEou9JOXzqys',
    solrBranchHours: 'https://xqa2.location.enterprise.com/enterprise-sls/search/location/enterprise/web/hours',
    solrSpatial: 'https://xqa2.location.enterprise.com/enterprise-sls/search/location',
    solrLocator: 'https://xqa2.location.enterprise.com/enterprise-sls/search/location/dotcom/text',
    tireRepairAccounts: {
      Firestone: 'ACCT_NUM_FIRESTONE',
      Goodyear: 'ACCT_NUM_GOODYEAR',
    },
  },
  localhost: {},
  sandbox: {},
  int: {
    serviceProxyUrl: 'https://sandbox.api.ehi.dev/rental/operations/roadside',
    // serviceProxyUrl: 'https://int.api.ehi.dev/rental/operations/roadside', eventually we should get an INT service-proxy
  },
  xqa: {
    serviceProxyUrl: 'https://xqa.api.ehi.dev/rental/operations/roadside',
  },
  uat: {
    serviceProxyUrl: 'https://uat.api.ehi.dev/rental/operations/roadside',
  },
  prod: {
    msalClientId: 'a17de546-2167-4412-a12a-3f89749e1618',
    msalAuthority: 'https://login.microsoftonline.com/5a9bb941-ba53-48d3-b086-2927fea7bf01',
    msalScopes: ['api://ar.oauth-proxy-prod.roadside_assistance_hero/api.read'],
    serviceProxyUrl: 'https://api.ehi.com/rental/operations/roadside',
    rentalAgreementUrn: 'urn:com.ehi:prd:rental.fulfillment:rentalAgreement:',
    vehicleUrn: 'urn:com.ehi:prd:vehicle:fleetVehicle:',

    noReplyExchangeEmailsCsv: 'noreply-vehicle-exchange@em.com',
    noReplyEscalationEmailsCsv: 'noreply-escalation@em.com',
    noReplyRecoveryEmailsCsv: 'noreply-vehicle-recovery@em.com',
    recoveryEmailsCsv: 'dltitles@ehi.com,vehiclerecovery@em.com',
    ldrEmailsCsv: 'roadsidecustomerconcern@em.com',
    exchangeEmailsCsv: 'TowExchange@em.com',
    zendeskEmailsCsv: 'Roadside_Priority_Alert@em.com',

    googleMapKey: 'AIzaSyA7e2U-FMjAtYhYT0KrwOB4h8Ksci1vHsc',
    solrBranchHours: 'https://prd.location.enterprise.com/enterprise-sls/search/location/enterprise/web/hours',
    solrSpatial: 'https://prd.location.enterprise.com/enterprise-sls/search/location',
    solrLocator: 'https://prd.location.enterprise.com/enterprise-sls/search/location/dotcom/text',
    tireRepairAccounts: {
      Firestone: '05413338A',
      Goodyear: '2807',
    },
  },
}

let envName

// One of three prod url's for the time being
const PROD_URLS = ['hero.ehi.com', 'staging.hero.ehi.com', 'prd.hero.ehi.com']
if (window.location.hostname === 'localhost') {
  envName = 'localhost'
} else if (PROD_URLS.some((url) => url.includes(window.location.hostname))) {
  envName = 'prod'
} else {
  envName = window.location.hostname.split('.')[0]
}

const defaultConfig = appConfig.default
const envConfig = appConfig[envName]
const mergedConfig = merge(defaultConfig, envConfig)
export const config: Config = { ...mergedConfig, envName }
